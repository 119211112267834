import { Box } from '@chakra-ui/react';
import React from 'react';

const FruitfulDarkBlue = props => (
  <Box
    as="svg"
    fill="none"
    viewBox="0 0 1900 193"
    width="1900"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1214.43 137.6L1223.34 136.77C1229.76 136.15 1232.25 134.7 1232.25 133.25V18.4499C1232.25 16.7899 1231.01 16.1699 1221.68 15.3399L1214.43 14.7199L1213.81 11.3999H1273.9L1273.28 14.7199L1264.58 15.5499C1257.95 16.1699 1255.67 17.6199 1255.67 19.0699V133.87C1255.67 135.53 1256.91 136.15 1266.24 136.98L1273.28 137.6L1273.9 140.92H1213.81L1214.43 137.6Z"
      fill="#BAAFAD"
    />
    <path
      d="M1287.37 137.6L1291.31 137.39C1297.32 136.98 1299.6 136.35 1299.6 133.87V77.71C1299.6 64.86 1297.94 62.79 1290.9 61.13L1286.13 60.09L1286.34 57.6L1320.32 48.48L1319.91 65.47H1320.32C1329.64 57.8 1341.66 48.27 1355.13 48.27C1361.76 48.27 1367.56 50.34 1371.71 54.69C1376.68 59.66 1378.55 67.74 1378.55 77.07V134.06C1378.55 135.51 1379.17 136.55 1388.7 137.38L1391.81 137.59L1392.43 140.91H1345.81L1346.43 137.59L1350.37 137.38C1357.41 136.97 1358.04 135.72 1358.04 133.86V82.68C1358.04 67.76 1352.03 62.58 1340.22 62.58C1331.72 62.58 1324.89 66.1 1319.91 70.04V134.28C1319.91 136.14 1321.15 136.77 1329.23 137.39L1333.17 137.6L1333.58 140.92H1286.75L1287.37 137.6Z"
      fill="#BAAFAD"
    />
    <path
      d="M1406.94 138.84L1405.49 112.52L1410.05 112.31L1414.82 124.33C1420.21 133.86 1427.46 139.87 1437.41 139.87C1448.18 139.87 1456.06 133.03 1456.06 123.29C1456.06 113.34 1446.32 108.37 1433.89 103.81C1420.01 98.63 1407.37 92.62 1407.37 75.84C1407.37 59.68 1420.63 48.28 1440.11 48.28C1447.98 48.28 1454.82 49.73 1460 51.8L1467.46 49.52L1468.5 76.46L1464.36 76.67L1453.58 57.4C1450.26 54.91 1445.29 52.22 1439.49 52.22C1429.34 52.22 1423.53 58.23 1423.53 66.73C1423.53 76.68 1431.82 80.2 1444.87 85.17C1459.38 90.77 1472.64 96.77 1472.64 114.18C1472.64 129.51 1460 143.81 1435.96 143.81C1424.55 143.81 1414.6 141.74 1406.94 138.84Z"
      fill="#BAAFAD"
    />
    <path
      d="M1536.65 170.13C1536.65 158.32 1549.29 150.44 1560.48 146.09V145.68C1548.25 144.23 1539.55 137.18 1539.55 128.69C1539.55 118.33 1550.74 110.45 1561.72 107.55V107.14C1551.56 103.2 1543.07 94.09 1543.07 80.41C1543.07 62.59 1558.82 48.5 1579.95 48.5C1589.69 48.5 1597.98 51.4 1604.61 55.55L1631.76 52.23L1630.31 63.83L1609.59 60.72L1609.38 61.55C1613.52 65.49 1616.63 71.7 1616.63 78.75C1616.63 96.36 1600.67 110.04 1579.12 110.04C1574.77 110.04 1569.38 109.42 1566.27 108.8C1560.47 110.04 1555.49 113.77 1555.49 118.75C1555.49 124.76 1561.29 127.25 1571.45 127.25C1578.29 127.25 1589.06 126.63 1596.52 126.63C1615.38 126.63 1626.98 136.37 1626.98 152.12C1626.98 174.71 1605.02 192.11 1572.28 192.11C1549.71 192.1 1536.65 184.01 1536.65 170.13ZM1613.11 163.09C1613.11 152.73 1606.07 146.72 1588.66 145.89C1582.65 145.68 1573.53 146.1 1565.04 146.72C1559.03 149.21 1553.02 156.25 1553.02 164.75C1553.02 177.8 1565.66 186.71 1583.27 186.71C1600.89 186.71 1613.11 176.14 1613.11 163.09ZM1596.74 80.61C1596.74 63.62 1591.77 52.01 1579.75 52.01C1569.8 52.01 1563.79 61.75 1563.79 78.33C1563.79 95.94 1570.01 106.51 1579.75 106.51C1590.53 106.51 1596.74 97.4 1596.74 80.61Z"
      fill="#BAAFAD"
    />
    <path
      d="M1639.43 137.6L1643.16 137.39C1648.96 136.98 1651.24 136.35 1651.24 133.87V23.21C1651.24 16.99 1650 15.34 1644.61 13.89L1637.77 11.4L1637.98 9.12L1671.96 0V65.27L1672.38 65.48C1681.7 57.81 1693.52 48.28 1707.19 48.28C1713.61 48.28 1719.42 50.56 1723.77 54.7C1728.54 59.88 1730.61 67.75 1730.61 76.87V134.06C1730.61 135.51 1731.02 136.55 1740.56 137.38L1743.67 137.59L1744.29 140.91H1697.46L1698.08 137.59L1702.22 137.38C1709.06 136.97 1710.09 135.72 1710.09 133.86V82.47C1710.09 67.76 1703.67 62.58 1692.06 62.58C1683.56 62.58 1676.73 66.1 1671.96 70.04V134.28C1671.96 136.14 1673 136.77 1681.28 137.39L1685.22 137.6L1685.84 140.92H1638.8L1639.43 137.6Z"
      fill="#BAAFAD"
    />
    <path
      d="M1765.21 118.12L1766.25 61.9599L1749.67 61.3399L1751.53 56.1599L1764.59 46.2099L1783.65 30.8799L1786.76 31.4999L1786.55 51.3899H1818.46L1815.56 61.1299H1786.55L1785.31 115.01C1785.1 126.41 1790.28 131.59 1800.85 131.59C1807.69 131.59 1813.49 129.1 1818.05 125.58L1819.08 127.65C1810.59 136.56 1800.02 143.61 1788.83 143.61C1773.5 143.61 1765.01 135.73 1765.21 118.12Z"
      fill="#BAAFAD"
    />
    <path
      d="M1834.01 138.84L1832.56 112.52L1837.12 112.31L1841.89 124.33C1847.28 133.86 1854.53 139.87 1864.48 139.87C1875.25 139.87 1883.13 133.03 1883.13 123.29C1883.13 113.34 1873.39 108.37 1860.96 103.81C1847.08 98.63 1834.44 92.62 1834.44 75.84C1834.44 59.68 1847.7 48.28 1867.18 48.28C1875.05 48.28 1881.89 49.73 1887.07 51.8L1894.53 49.52L1895.57 76.46L1891.43 76.67L1880.65 57.4C1877.33 54.91 1872.36 52.22 1866.56 52.22C1856.41 52.22 1850.6 58.23 1850.6 66.73C1850.6 76.68 1858.89 80.2 1871.94 85.17C1886.45 90.77 1899.71 96.77 1899.71 114.18C1899.71 129.51 1887.07 143.81 1863.03 143.81C1851.62 143.81 1841.68 141.74 1834.01 138.84Z"
      fill="#BAAFAD"
    />
    <path
      d="M203.9 11.8799H300.04V44.0899H236.94V66.9999H289.91V97.0499H236.95V141.38H203.91V11.8799H203.9Z"
      fill="#2B2A30"
    />
    <path
      d="M385.89 11.8799C419.1 11.8799 434.37 33.4699 434.37 59.1999C434.37 77.2999 426.23 94.2399 408.8 102.21L436.86 141.4H397.17L372.6 106.7H355.66V141.4H322.62V11.8799H385.89ZM355.67 42.0999V76.6399H384.06C397.34 76.6399 402.33 68.4999 402.33 59.5399C402.33 49.5799 397.35 42.0999 384.06 42.0999H355.67Z"
      fill="#2B2A30"
    />
    <path
      d="M574.351 11.8799V84.7699C574.351 118.15 553.431 143.88 514.741 143.88C475.891 143.88 455.301 118.14 455.301 84.7699V11.8799H488.511V82.7799C488.511 101.54 497.481 111.84 514.741 111.84C532.181 111.84 541.311 101.54 541.311 82.7799V11.8799H574.351V11.8799Z"
      fill="#2B2A30"
    />
    <path d="M632.301 11.8799V141.4H599.091V11.8799H632.301Z" fill="#2B2A30" />
    <path
      d="M655.881 11.8799H769.791V44.0899H729.441V141.39H696.231V44.0899H655.881V11.8799V11.8799Z"
      fill="#2B2A30"
    />
    <path
      d="M793.86 11.8799H890V44.0899H826.9V66.9999H879.87V97.0499H826.9V141.38H793.86V11.8799Z"
      fill="#2B2A30"
    />
    <path
      d="M1032.47 11.8799V84.7699C1032.47 118.15 1011.55 143.88 972.86 143.88C934.01 143.88 913.42 118.14 913.42 84.7699V11.8799H946.63V82.7799C946.63 101.54 955.6 111.84 972.86 111.84C990.3 111.84 999.43 101.54 999.43 82.7799V11.8799H1032.47V11.8799Z"
      fill="#2B2A30"
    />
    <path
      d="M1057.38 11.8799H1090.42V109.35H1152.52V141.4H1057.38V11.8799V11.8799Z"
      fill="#2B2A30"
    />
    <path
      d="M1513.18 46.8101H1503.31C1497.79 46.8101 1493.32 51.28 1493.32 56.8V88.11C1493.32 90.73 1495.32 92.8601 1497.87 93.1201V140.91H1518.62V93.1201C1521.17 92.8601 1523.17 90.73 1523.17 88.11V56.8C1523.17 51.29 1518.7 46.8101 1513.18 46.8101Z"
      fill="#00D3C5"
    />
    <path
      d="M1495.08 18.79C1495.08 12.57 1499.43 5.53003 1508.14 5.53003C1517.05 5.53003 1521.4 12.57 1521.4 18.79C1521.4 24.8 1517.05 32.05 1508.14 32.05C1499.43 32.06 1495.08 24.8 1495.08 18.79Z"
      fill="#00D3C5"
    />
    <path
      d="M64.48 140.83C100.091 140.83 128.96 111.961 128.96 76.3501C128.96 40.7388 100.091 11.8701 64.48 11.8701C28.8687 11.8701 0 40.7388 0 76.3501C0 111.961 28.8687 140.83 64.48 140.83Z"
      fill="#00D3C5"
    />
  </Box>
);

export default FruitfulDarkBlue;
