import React from 'react';

import {
  FruitfulDarkBlue,
  FruitfulDarkYellow,
  FruitfulLightBlue,
  FruitfulLightYellow,
} from './icons';

export type LogoVariants =
  | 'lightYellow'
  | 'darkYellow'
  | 'lightBlue'
  | 'darkBlue';

interface ILogo {
  variant: LogoVariants;
  w?: number | string;
}

const Logo = ({ w, variant }: ILogo) => {
  switch (variant) {
    case 'lightYellow':
      return <FruitfulLightYellow maxWidth={244} w={w} />;
    case 'darkYellow':
      return <FruitfulDarkYellow maxWidth={244} w={w} />;
    case 'lightBlue':
      return <FruitfulLightBlue maxWidth={244} w={w} />;
    case 'darkBlue':
      return <FruitfulDarkBlue maxWidth={244} w={w} />;
    default:
      break;
  }
};
Logo.defaultProps = {
  variant: 'lightYellow',
};

export default Logo;
